.fade-from-bottom {
  animation: fade-in-bottom 1s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    -o-transform: scale(0.2);
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
    -o-transform: scale(1.8);
    transform: scale(1.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.animation-scale-up {
  -webkit-animation-name: fade-scale-02;
  -o-animation-name: fade-scale-02;
  animation-name: fade-scale-02;
}

.animation-scale-down {
  -webkit-animation-name: fade-scale-18;
  -o-animation-name: fade-scale-18;
  animation-name: fade-scale-18;
}
