$theme-colors: (
  'primary': #003a9b,
  'secondary': #ff8c00,
  'success': #00b578,
  'warning': #ff8f1f,
  'danger': #ff3141,
  // 'info': $info,
  // 'light': $light,
  // 'dark': $dark,
  'link': #003a9b
);

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

div[role='dialog'] {
  padding: 0 !important;
}

@import '~bootstrap/scss/bootstrap';

:root {
  //V3全局引用bootstrap变量值,按需创建css变量
  --bs-color-white: $color-white;
  --bs-font-weight-bold: #{$font-weight-bold};
  @each $key, $value in $colors {
    --bs-color-#{$key}: #{$value};
  }
  @each $key, $value in $grays {
    --bs-color-gray-#{$key}: #{$value};
  }
}
