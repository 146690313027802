* {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Helvetica Neue',
    Helvetica,
    Segoe UI,
    Arial,
    Roboto,
    'PingFang SC',
    miui,
    'Hiragino Sans GB',
    'Microsoft Yahei',
    sans-serif;
}

ul,
dl {
  margin: 0;
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover {
    color: var(--link-color-hover);
    text-decoration: underline;
  }
}

.custom-scroll {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
    background-color: #fff;
  }

  &:-webkit-scrollbar:hover {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(158, 158, 158, 0.6);
    border-radius: 8px;
    transition: width 0.3s ease;
  }
}

[data-animation-in] {
  opacity: 0;
}

.hide {
  display: none !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs {
    display: block !important;
  }
}

@media (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }

  .visible-md {
    display: block !important;
  }
}

@media (max-width: 1439px) {
  .hidden-lg {
    display: none !important;
  }

  .visible-lg {
    display: block !important;
  }
}

// Toastif
.Toastify__toast {
  min-height: 54px;
  font-size: 14px;
}

.Toastify__toast-container--top-center {
  top: 8px;
}

// next/image 自适应
.lazy-image-fill {
  position: relative !important;
  height: auto !important;
}
