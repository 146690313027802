:root {
  --primary-color: #003a9b; // 全局主色
  --primary-color-click: #1650b1; // 主色-点击色-#002768
  --primary-color-hover: #1650b1; // 主色-悬浮色-#002768
  --link-color: #002e87; // 文字链接色
  --link-color-click: #003a9b; // 文字链接色-点击色
  --link-color-hover: #003a9b; // 文字链接色-悬浮色
  --secondary-color: #ff8c00; // 辅助色
  --secondary-color-click: #ff9916; // 辅助色-点击
  --secondary-color-hover: #ff9916; // 辅助色-悬浮色
  --success-color: #00b578; // 成功色
  --warning-color: #ff8f1f; // 警告色
  --danger-color: #ff3141; // 危险色
  --color-bg: #f6f6f7; // 背景色
  --font-size-body: 16px; // 正文字号
  --text-color-primary: #212121; // 主文本色

  // 全局提示
  --toastify-color-warning: #ff9916;
  --toastify-color-success: #66bb6a;

  // 通用头部导航高度
  --yq-navbar-height: 72px;
  --yq-navbar-md-height: 64px;
}
