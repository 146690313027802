@font-face {
  font-family: '17icon';
  font-style: normal;
  font-weight: normal;

  src: url('https://res.17track.net/asset/fonts/common/common/common.eot?v=5e46080d7f');
  src:
    url('https://res.17track.net/asset/fonts/common/common/common.eot?v=5e46080d7f#iefix') format('embedded-opentype'),
    url('https://res.17track.net/asset/fonts/common/common/common.woff?v=a1621cc15d') format('woff'),
    url('https://res.17track.net/asset/fonts/common/common/common.ttf?v=f330d4dec4') format('truetype'),
    url('https://res.17track.net/asset/fonts/common/common/common.svg?v=edbdaa10b7#17icon') format('svg');
}
[class^='fa-'],
[class*='fa-'] {
  position: relative;
  display: inline-block;
  font-family: '17icon';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);

  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-arrow-forward:before {
  content: '\f608';
}

/* 分享 */
.fa-share-11:before {
  content: '\c001';
}
.fa-share-12:before {
  content: '\c009';
}
.fa-share-13:before {
  content: '\c005';
}
.fa-share-14:before {
  content: '\c007';
}
.fa-share-15:before {
  content: '\c00c';
}
.fa-share-21:before {
  content: '\c015';
}
.fa-share-22:before {
  content: '\c00f';
}
.fa-share-23:before {
  content: '\c00e';
}
.fa-share-24:before {
  content: '\c017';
}
.fa-share-25:before {
  content: '\c016';
}

.fa-notifications:before {
  content: '\f042';
}

.fa-envelope:before {
  content: '\f023';
}

.fa-yqtrack:before {
  content: '\f069';
}

.fa-times-circle:before {
  content: '\f010';
}

.fa-user:before {
  content: '\f013';
}
.fa-trash-o:before {
  content: '\f012';
}

.fa-chevron-right:before {
  content: '\f602';
}

.fa-chevron-left:before {
  content: '\f601';
}

.fa-clear:before {
  content: '\f00e';
}

.fa-home:before {
  content: '\f02f';
}

.fa-phone-msg:before {
  content: '\f08f';
}

.fa-truck:before {
  content: '\f101';
}

.fa-auto:before {
  content: '\f047';
}

.fa-globe:before {
  content: '\f008';
}

.fa-share-black:before {
  content: '\f03c';
}
.fa-search:before {
  content: '\f03b';
}

.fa-download:before {
  content: '\f02a';
}

.fa-filter:before {
  content: '\f03a';
}
.fa-publish:before {
  content: '\f029';
}
.fa-comments-o:before {
  content: '\f017';
}

.fa-wechat:before {
  content: '\c013';
}

.fa-weibo:before {
  content: '\c00f';
}

.fa-twitter:before {
  content: '\c009';
}

.fa-linkedin:before {
  content: '\c007';
}

.fa-facebook:before {
  content: '\c001';
}

[class^='spr-avatar-'],
[class*='spr-avatar-'] {
  width: 30px !important;
  height: 30px !important;
  background-image: url('https://res.17track.net/asset/imgs-sprites/avatar/30x30.png');
}

.spr-avatar-0 {
  background-position: 0 -360px;
}

.spr-avatar-10001 {
  background-position: 0 -330px;
}

.spr-avatar-10002 {
  background-position: 0 -300px;
}
.spr-avatar-10003 {
  background-position: 0 -270px;
}

.spr-avatar-10004 {
  background-position: 0 -240px;
}

.spr-avatar-10005 {
  background-position: 0 -210px;
}

.spr-avatar-10006 {
  background-position: 0 -180px;
}

.spr-avatar-10007 {
  background-position: 0 -150px;
}

.spr-avatar-10008 {
  background-position: 0 -120px;
}

.spr-avatar-10009 {
  background-position: 0 -90px;
}

.spr-avatar-10010 {
  background-position: 0 -60px;
}
.spr-avatar-10011 {
  background-position: 0 -30px;
}

.spr-avatar-10012 {
  background-position: 0 0;
}

[class*='spr-apps-'],
[class^='spr-apps-'] {
  width: 54px !important;
  height: 54px !important;
  background-image: url(https://res.17track.net/asset/imgs-sprites/application/54x54.png?v=2e1347fb01);
}

.spr-apps-ShopifyApp {
  background-position: -54px -162px;
}

.spr-apps-api {
  background-position: -108px 0;
}

.spr-apps-app {
  background-position: -162px 0;
}

.spr-apps-www {
  background-position: -162px -162px;
}

[class*='spr-apps-24x24-'],
[class^='spr-apps-24x24-'] {
  width: 24px !important;
  height: 24px !important;
  background-image: url(https://res.17track.net/asset/imgs-sprites/application/24x24.png);
}

.spr-apps-24x24-www {
  background-position: -72px -72px;
}

.spr-apps-24x24-api {
  background-position: -48px 0;
}

.spr-apps-24x24-ShopifyApp {
  background-position: -24px -72px;
}

.spr-apps-24x24-app {
  background-position: -72px 0;
}

.spr-apps-24x24-extcall {
  background-position: 0 -48px;
}

.spr-apps-24x24-carrierlist {
  background-position: -48px -24px;
}

.spr-apps-24x24-links {
  background-position: -96px -48px;
}

[class^='enum-avatar-bg-'],
[class*='enum-avatar-bg-'] {
  display: block;
  width: 120px !important;
  height: 120px !important;
  background-color: #fff;
  background-position: center center;
  background-size: 100% 100%;
}

.enum-avatar-bg-0 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/0.png');
}
.enum-avatar-bg-10001 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10001.png');
}
.enum-avatar-bg-10002 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10002.png');
}
.enum-avatar-bg-10003 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10003.png');
}
.enum-avatar-bg-10004 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10004.png');
}
.enum-avatar-bg-10005 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10005.png');
}
.enum-avatar-bg-10006 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10006.png');
}
.enum-avatar-bg-10007 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10007.png');
}
.enum-avatar-bg-10008 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10008.png');
}
.enum-avatar-bg-10009 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10009.png');
}
.enum-avatar-bg-10010 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10010.png');
}
.enum-avatar-bg-10011 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10011.png');
}
.enum-avatar-bg-10012 {
  background-image: url('https://res.17track.net/asset/imgs-enum/avatar/120x120/10012.png');
}

[class^='enum-buyervip-bg-24x24-'],
[class*='enum-buyervip-bg-24x24-'] {
  display: block;
  width: 24px !important;
  height: 24px !important;
  background-color: #fff;
  background-position: center center;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

[class^='enum-buyervip-bg-40x40-'],
[class*='enum-buyervip-bg-40x40-'] {
  display: block;
  width: 40px !important;
  height: 40px !important;
  background-color: #fff;
  background-position: center center;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.enum-buyervip-bg-24x24-0 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/24x24/0.png');
}
.enum-buyervip-bg-24x24-4010 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/24x24/4010.png');
}
.enum-buyervip-bg-24x24-4020 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/24x24/4020.png');
}
.enum-buyervip-bg-40x40-0 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/40x40/0.png');
}
.enum-buyervip-bg-40x40-4010 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/40x40/4010.png');
}
.enum-buyervip-bg-40x40-4020 {
  background-image: url('https://res.17track.net/asset/imgs-enum/buyer-vip/40x40/4020.png');
}

// yq组件库code-design
.yq-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
  overflow: hidden;
}
